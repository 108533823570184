
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import fundsBill from './components/statistics/fundsBill/index.vue';
import billingDetails from './components/statistics/billingDetails/index.vue';
import merchantIncoming from './components/paymentAccount/merchantIncoming/index.vue';
import merchantManage from './components/paymentAccount/merchantManage/index.vue';
import wechatPaySpecialMerchant from './components/paymentAccount/wechatPaySpecialMerchant/index.vue';
@Component({
  name: 'merchant',
  components: {
    Submenu,
    fundsBill,
    billingDetails,
    merchantIncoming,
    merchantManage,
    wechatPaySpecialMerchant,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '支付账户',
      list: [
        {name: '账户列表', active: true},
        // {name: '商户进件', active: false},
        {name: '支付账户进件', active: false},
      ],
    },
    {
      top: '账户统计',
      list: [
        {name: '资金账单', active: false},
        {name: '账单明细', active: false},
      ],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '账户列表';
  }
}
