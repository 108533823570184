
import {Component, Vue} from 'vue-property-decorator';
import wechatPaySpecialMerchantList from './list.vue';
import wechatPaySpecialMerchantSubject from './chooseSubject.vue';
import wechatPaySpecialMerchantInfo from './information.vue';
import wechatPaySpecialMerchantDetails from './applicationDetails.vue';
import wechatPaySpecialMerchantSubjectInfor from './subjectInfor.vue';
import wechatPaySpecialMerchantBusinessInfor from './businessInfor.vue';
import settlementAccount from './settlementAccount.vue';
import wechatPaySpecialMerchantSuperAdmin from './superAdmin.vue';
import otherData from './otherData.vue';
import applicationProgress from './applicationProgress.vue';
@Component({
  name: 'wechatPaySpecialMerchant',
  components: {
    wechatPaySpecialMerchantList,
    wechatPaySpecialMerchantSubject,
    wechatPaySpecialMerchantInfo,
    wechatPaySpecialMerchantDetails,
    wechatPaySpecialMerchantSubjectInfor,
    wechatPaySpecialMerchantBusinessInfor,
    settlementAccount,
    wechatPaySpecialMerchantSuperAdmin,
    otherData,
    applicationProgress,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
