
import {Component, Vue} from 'vue-property-decorator';
import {payMerchants} from '../../../../../resources';
import {
  WechatPayMerchantSpec,
  WechatPaySpecialMerchantSpec,
} from '@/externals/MaxCI-PayMerchant-v1';
import {ObjectId} from 'bson';
import moment from 'moment';
import privateImg from '@/components/privateImg/index.vue';
@Component({
  name: 'applicationDetails',
  components: {
    privateImg,
  },
})
export default class extends Vue {
  private detailMsg:
    | null
    | WechatPayMerchantSpec
    | WechatPaySpecialMerchantSpec = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  async created() {
    try {
      const payMerchant = (
        await payMerchants.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      if (payMerchant?.spec.type === '微信') {
        this.detailMsg = payMerchant?.spec ?? null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //转换时间
  private getTime(time: Date | string) {
    return moment(time).format('YYYY-MM-DD');
  }
  private backBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
