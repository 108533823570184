
import {Component, Vue} from 'vue-property-decorator';
import merchantIncomingList from './list.vue';
import chooseSubject from './chooseSubject.vue';
import information from './information.vue';
import applicationDetails from './applicationDetails.vue';
import subjectInfor from './subjectInfor.vue';
import businessInfor from './businessInfor.vue';
import superAdmin from './superAdmin.vue';
import otherData from './otherData.vue';
import applicationProgress from './applicationProgress.vue';
@Component({
  name: 'merchantIncomingIndex',
  components: {
    merchantIncomingList,
    chooseSubject,
    information,
    applicationDetails,
    subjectInfor,
    businessInfor,
    superAdmin,
    otherData,
    applicationProgress,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
