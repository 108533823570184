
import {Component, Vue} from 'vue-property-decorator';
import {
  wechatPaySpecialMerchantImports,
  wechatPaySpecialMerchantImportRequests,
} from '../../../../../resources';
import {
  WechatPaySpecialMerchantImport,
  WechatPaySpecialMerchantImportStatus,
} from '@/externals/MaxCI-WechatPaySpecialMerchantImport-v1';
import {ObjectId} from 'bson';
import moment from 'moment';
import privateImg from '@/components/privateImg/index.vue';
@Component({
  name: 'wechatPaySpecialMerchantDetails',
  components: {
    privateImg,
  },
})
export default class extends Vue {
  private dialogSubmit = false;
  private detailMsg: null | WechatPaySpecialMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  async created() {
    try {
      const wechatPaySpecialMerchantImport = (
        await wechatPaySpecialMerchantImports.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      this.detailMsg = wechatPaySpecialMerchantImport ?? null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //获取状态
  private getStatus(status: WechatPaySpecialMerchantImportStatus | undefined) {
    let statusText = '';
    if (status) {
      statusText = status.phase ?? '';
    } else {
      statusText = '待提交';
    }
    return statusText;
  }
  private submitForm() {
    wechatPaySpecialMerchantImportRequests
      .create(
        [
          {
            spec: {
              type: '提交',
              importId: this.detailId,
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res.length > 0) {
          this.$router.push({
            query: {
              menu: this.$route.query.menu,
              $type: 'applicationProgress',
              id: this.$route.query.id,
            },
          });
        }
      });
  }
  //转换时间
  private getTime(time: Date | string) {
    return moment(time).format('YYYY-MM-DD');
  }
  private backBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
