
import {Component, Vue} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {wechatPayMerchantImports} from '../../../../../resources';
import {WechatPayMerchantImport} from '@/externals/MaxCI-WechatPayMerchantImport-v1';
import {ObjectId} from 'bson';
import {AxiosError} from 'axios';

@Component({
  name: 'superAdmin',
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validIdCardNumber = (rule: any, value: string, callback: any) => {
    if (
      !/(^[a-zA-Z0-9()（）]{6,12}$)/.test(value) &&
      !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)
    ) {
      callback(new Error('证件号码格式不正确'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validPhone = (rule: any, value: string, callback: any) => {
    if (!/(^1[23456789]\d{9}$)/.test(value)) {
      callback(new Error('手机号格式不正确'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validEmail = (rule: any, value: string, callback: any) => {
    if (
      !/(^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$)/.test(
        value,
      )
    ) {
      callback(new Error('邮箱格式不正确'));
    } else {
      callback();
    }
  };
  private formData = {
    contactType: '法人', //超级管理员类型
    contactName: '', //超级管理员姓名
    contactIdCardNumber: '', //超级管理员证件号码
    mobilePhone: '', //超级管理员手机
    contactEmail: '', //超级管理员邮箱
  }; //提交数据
  private rules = {
    contactType: [
      {required: true, message: '请选择超级管理员类型', trigger: 'blur'},
    ],
    contactName: [
      {required: true, message: '请输入超级管理员姓名', trigger: 'blur'},
    ],
    contactIdCardNumber: [
      {required: true, message: '请输入证件号码', trigger: 'blur'},
      {validator: this.validIdCardNumber, trigger: 'blur'},
    ],
    mobilePhone: [
      {required: true, message: '请输入手机号码', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
    contactEmail: [
      {required: true, message: '请输入邮箱', trigger: 'blur'},
      {validator: this.validEmail, trigger: 'blur'},
    ],
  }; //提交验证
  private submitFlag = false; //提交开关
  private detailMsg: null | WechatPayMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );

  async created() {
    try {
      const wechatPayMerchantImport = (
        await wechatPayMerchantImports.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      this.detailMsg = wechatPayMerchantImport ?? null;
      this.formData.contactType =
        this.detailMsg?.spec.common?.contactInfo?.contactType ?? '法人';
      if (this.formData.contactType === '负责人') {
        this.formData.contactName =
          this.detailMsg?.spec.common?.contactInfo?.contactName ?? '';
        this.formData.contactIdCardNumber =
          this.detailMsg?.spec.common?.contactInfo?.contactIdCardNumber ?? '';
      } else {
        if (this.detailMsg?.spec.common?.idInfo?.idDocType === '身份证') {
          this.formData.contactName =
            this.detailMsg?.spec.common?.idInfo?.idCardName ?? '';
          this.formData.contactIdCardNumber =
            this.detailMsg?.spec.common?.idInfo?.idCardNumber ?? '';
        } else if (
          this.detailMsg?.spec.common?.idInfo?.idDocType === '护照' ||
          this.detailMsg?.spec.common?.idInfo?.idDocType ===
            '中国香港居民来往内地通行证' ||
          this.detailMsg?.spec.common?.idInfo?.idDocType ===
            '中国澳门居民来往内地通行证' ||
          this.detailMsg?.spec.common?.idInfo?.idDocType ===
            '中国台湾居民来往大陆通行证'
        ) {
          this.formData.contactName =
            this.detailMsg?.spec.common?.idInfo?.idDocName ?? '';
          this.formData.contactIdCardNumber =
            this.detailMsg?.spec.common?.idInfo?.idDocNumber ?? '';
        }
      }
      this.formData.mobilePhone =
        this.detailMsg?.spec.common?.contactInfo?.mobilePhone ?? '';
      this.formData.contactEmail =
        this.detailMsg?.spec.common?.contactInfo?.contactEmail ?? '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'information',
        id: this.$route.query.id,
      },
    });
  }
  //切换超管身份
  private changeContactType() {
    if (this.formData.contactType === '负责人') {
      this.formData.contactName = '';
      this.formData.contactIdCardNumber = '';
    } else {
      if (this.detailMsg?.spec.common?.idInfo?.idDocType === '身份证') {
        this.formData.contactName =
          this.detailMsg?.spec.common?.idInfo?.idCardName ?? '';
        this.formData.contactIdCardNumber =
          this.detailMsg?.spec.common?.idInfo?.idCardNumber ?? '';
      } else if (
        this.detailMsg?.spec.common?.idInfo?.idDocType === '护照' ||
        this.detailMsg?.spec.common?.idInfo?.idDocType ===
          '中国香港居民来往内地通行证' ||
        this.detailMsg?.spec.common?.idInfo?.idDocType ===
          '中国澳门居民来往内地通行证' ||
        this.detailMsg?.spec.common?.idInfo?.idDocType ===
          '中国台湾居民来往大陆通行证'
      ) {
        this.formData.contactName =
          this.detailMsg?.spec.common?.idInfo?.idDocName ?? '';
        this.formData.contactIdCardNumber =
          this.detailMsg?.spec.common?.idInfo?.idDocNumber ?? '';
      }
    }
  }
  //提交
  private submitForm(type: string) {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          wechatPayMerchantImports
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec')('common')('contactInfo')('contactType'),
                    this.formData.contactType as '法人' | '负责人',
                  )(
                    f => f('spec')('common')('contactInfo')('contactName'),
                    this.formData.contactName,
                  )(
                    f =>
                      f('spec')('common')('contactInfo')('contactIdCardNumber'),
                    this.formData.contactIdCardNumber,
                  )(
                    f => f('spec')('common')('contactInfo')('mobilePhone'),
                    this.formData.mobilePhone,
                  )(
                    f => f('spec')('common')('contactInfo')('contactEmail'),
                    this.formData.contactEmail,
                  ),
                ),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                if (type) {
                  this.$router.push({
                    query: {
                      menu: this.$route.query.menu,
                      type: type,
                      id: this.$route.query.id,
                    },
                  });
                }
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
}
