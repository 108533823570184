
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {
  wechatPaySpecialMerchantImports,
  wechatPaySpecialMerchantImportRequests,
} from '../../../../../resources';
import {ObjectId} from 'bson';
import {
  WechatPaySpecialMerchantImport,
  WechatPaySpecialMerchantImportStatus,
} from '@/externals/MaxCI-WechatPaySpecialMerchantImport-v1';
@Component({
  name: 'wechatPaySpecialMerchantList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private dialogRevoke = false; //撤销弹窗
  private total = 0;
  private list: Array<WechatPaySpecialMerchantImport> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private statusData = [
    {name: '全部', num: ''},
    {name: '已驳回', num: ''},
    {name: '待账户验证', num: ''},
    {name: '待签约', num: ''},
    {name: '已提交', num: ''},
    {name: '审核中', num: ''},
    {name: '完成', num: ''},
  ];
  private searchData = {status: '', applymentId: null as null | number};
  private operateId: null | ObjectId = null;
  async created() {
    this.updateList();
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //切换搜索条件
  private changeTab(label: string) {
    this.searchData.status = label;
    this.checkList();
  }
  //获取状态
  private getStatus(status: WechatPaySpecialMerchantImportStatus | undefined) {
    let statusText = '';
    if (status) {
      statusText = status.phase ?? '';
    } else {
      statusText = '待提交';
    }
    return statusText;
  }
  //查看进度
  private checkProgress(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'applicationProgress',
        id: id.toHexString(),
      },
    });
  }
  //继续填写
  private continueBtn(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'information',
        id: id.toHexString(),
      },
    });
  }
  //查看申请
  private viewApplication(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'applicationDetails',
        id: id.toHexString(),
      },
    });
  }
  //撤销弹窗
  private revokePopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogRevoke = true;
  }
  //撤销保存
  private revokeSumbit() {
    wechatPaySpecialMerchantImportRequests
      .create([
        {
          spec: {
            type: '撤销',
            importId: this.operateId as ObjectId,
            applicationId: ObjectId.createFromHexString(
              this.$route.params.applicationId,
            ),
          },
        },
      ])
      .then(res => {
        if (res.length > 0) {
          this.$message.success('撤销成功');
          this.updateList();
          this.dialogRevoke = false;
        }
      });
  }
  //查询列表
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await wechatPaySpecialMerchantImports.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query =>
                query(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              );
              and(query =>
                query(
                  f => f('status')('phase'),
                  e => e.$ne('撤销'),
                ),
              );
              if (this.searchData.status && this.searchData.status !== '全部') {
                and(query =>
                  query(
                    f => f('status')('phase'),
                    e =>
                      e.$eq(
                        this.searchData.status as
                          | '已提交'
                          | '编辑中'
                          | '审核中'
                          | '待签约'
                          | '待账户验证'
                          | '开通权限中'
                          | '完成'
                          | '已驳回'
                          | '作废'
                          | '撤销',
                      ),
                  ),
                );
              }
              if (this.searchData.applymentId) {
                and(query =>
                  query(
                    f => f('status')('applymentId'),
                    e => e.$eq(Number(this.searchData.applymentId)),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'chooseSubject'},
    });
  }
}
