
import {Component, Vue} from 'vue-property-decorator';
import {uploadImg} from '../../../../../api/publicMethod';
import {PlatformConfig} from '@/externals/MaxCI-PlatformConfig-v1';
import {Form} from 'element-ui';
import {
  platformConfigs,
  wechatPayMerchantImports,
} from '../../../../../resources';
import {WechatPayMerchantImport} from '@/externals/MaxCI-WechatPayMerchantImport-v1';
import {ObjectId} from 'bson';
import {AxiosError} from 'axios';
import moment from 'moment';
import privateImg from '@/components/privateImg/index.vue';

@Component({
  name: 'subjectInfor',
  components: {
    privateImg,
  },
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validBusinessNum = (rule: any, value: string, callback: any) => {
    if (!/((^[a-zA-Z0-9]{15}$)|(^[a-zA-Z0-9]{18}$))/.test(value)) {
      callback(new Error('请输入15位或18位的数字/字母'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validmerchantName = (rule: any, value: string, callback: any) => {
    if (value) {
      if (
        this.organizationType === '个体工商户' ||
        this.organizationType === '事业单位'
      ) {
        const merchantName = value.slice(value.length - 2, value.length);
        if (merchantName === '公司' || merchantName === '企业') {
          callback(new Error('商户名称结尾不能是“公司”、“企业”'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validIdDocNumber = (rule: any, value: string, callback: any) => {
    if (!/(^[a-zA-Z0-9()（）]{6,12}$)/.test(value)) {
      callback(new Error('法人证件号码格式错误，请输入6-12位数字|字母|括号'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validIdCardNumber = (rule: any, value: string, callback: any) => {
    if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
      callback(new Error('身份证格式不正确'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validBusinessTimeEnd = (rule: any, value: string, callback: any) => {
    if (value && this.formData.businessTimeStart) {
      if (moment(this.formData.businessTimeStart).isBefore(value)) {
        const businessTimeStart = moment(this.formData.businessTimeStart);
        const businessTimeEnd = moment(value);
        const now = moment(new Date());
        const diff1 = businessTimeEnd.diff(businessTimeStart, 'day');
        const diff2 = businessTimeEnd.diff(now, 'day');
        if (diff1 > 60 && diff2 > 60) {
          callback();
        } else {
          callback(
            new Error('有效期必须大于60天，且失效时间距当前时间需要超过60天'),
          );
        }
      } else {
        callback(new Error('失效日期必须大于生效日期'));
      }
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validCardValidTimeEnd = (rule: any, value: string, callback: any) => {
    if (value && this.formData.idCardValidTimeStart) {
      if (moment(this.formData.idCardValidTimeStart).isBefore(value)) {
        const idCardValidTimeStart = moment(this.formData.idCardValidTimeStart);
        const idCardValidTimeEnd = moment(value);
        const now = moment(new Date());
        const diff1 = idCardValidTimeEnd.diff(idCardValidTimeStart, 'day');
        const diff2 = idCardValidTimeEnd.diff(now, 'day');
        if (diff1 > 60 && diff2 > 60) {
          callback();
        } else {
          callback(
            new Error('有效期必须大于60天，且失效时间距当前时间需要超过60天'),
          );
        }
      } else {
        callback(new Error('失效日期必须大于生效日期'));
      }
    } else {
      callback();
    }
  };
  private formData = {
    businessLicenseCopy: '', //证件扫描件
    businessLicenseNumber: '', //证件注册号
    merchantName: '', //商户名称
    legalPerson: '', //经营者/法定代表人姓名
    companyAddress: '', //注册地址
    businessType: '定期', //营业期限类型
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    businessTimeStart: null as null | Date, //营业期限生效日期
    businessTimeEnd: null as null | Date, //营业期限失效日期

    idDocType: '身份证' as
      | '身份证'
      | '护照'
      | '中国香港居民来往内地通行证'
      | '中国澳门居民来往内地通行证'
      | '中国台湾居民来往大陆通行证', //证件类型
    //身份证
    idCardCopy: '', //身份证人像面照片
    idCardNational: '', //身份证国徽面照片
    idCardName: '', //身份证姓名
    idCardNumber: '', //身份证号
    idCardType: '定期', //证件有效期类型
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    idCardValidTimeStart: null as null | Date, //身份证生效日期
    idCardValidTimeEnd: null as null | Date, //身份证失效日期
    //其他证件
    idDocName: '', //证件姓名
    idDocNumber: '', //证件号码
    idDocCopy: '', //证件照片
    docPeriodEnd: '', //证件结束日期
  }; //提交数据
  private rules = {
    businessLicenseCopy: [
      {required: true, message: '请上传营业执照照片', trigger: 'blur'},
    ],
    businessLicenseNumber: [
      {required: true, message: '请输入注册号', trigger: 'blur'},
      {validator: this.validBusinessNum, trigger: 'blur'},
    ],
    merchantName: [
      {required: true, message: '请输入商户名称', trigger: 'blur'},
      {validator: this.validmerchantName, trigger: 'blur'},
    ],
    legalPerson: [
      {required: true, message: '请输入经营者姓名', trigger: 'blur'},
    ],
    companyAddress: [
      {required: true, message: '请输入注册地址', trigger: 'blur'},
    ],
    businessTimeStart: [
      {required: true, message: '请选择生效日期', trigger: 'blur'},
    ],
    businessTimeEnd: [
      {required: true, message: '请选择失效日期', trigger: 'blur'},
      {validator: this.validBusinessTimeEnd, trigger: 'blur'},
    ],
    idCardCopy: [
      {required: true, message: '请上传身份证人像面照片', trigger: 'blur'},
    ],
    idCardNational: [
      {required: true, message: '请上传身份证国徽面照片', trigger: 'blur'},
    ],
    idCardName: [
      {required: true, message: '请输入身份证姓名', trigger: 'blur'},
    ],
    idCardNumber: [
      {required: true, message: '请输入身份证号', trigger: 'blur'},
      {validator: this.validIdCardNumber, trigger: 'blur'},
    ],
    idCardValidTimeStart: [
      {required: true, message: '请选择生效日期', trigger: 'blur'},
    ],
    idCardValidTimeEnd: [
      {required: true, message: '请选择失效日期', trigger: 'blur'},
      {validator: this.validCardValidTimeEnd, trigger: 'blur'},
    ],
    idDocName: [{required: true, message: '请输入证件姓名', trigger: 'blur'}],
    idDocNumber: [
      {required: true, message: '请输入证件号码', trigger: 'blur'},
      {validator: this.validIdDocNumber, trigger: 'blur'},
    ],
    idDocCopy: [{required: true, message: '请上传证件照片', trigger: 'blur'}],
    docPeriodEnd: [
      {required: true, message: '请选择证件结束日期', trigger: 'blur'},
    ],
  }; //提交验证
  private submitFlag = false; //提交开关
  private action = '';
  private imgData = {};
  private platformConfig: null | PlatformConfig = null; //平台配置信息
  private detailMsg: null | WechatPayMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private organizationType = this.$route.query.organizationType as string;

  async created() {
    try {
      //查询平台信息
      this.platformConfig =
        (await platformConfigs.find(stage => stage)).find(() => true) ?? null;
      //查询详情
      const wechatPayMerchantImport = (
        await wechatPayMerchantImports.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      this.detailMsg = wechatPayMerchantImport ?? null;
      if (
        this.detailMsg?.spec.organizationType === '个体工商户' ||
        this.detailMsg?.spec.organizationType === '企业' ||
        this.detailMsg?.spec.organizationType === '事业单位' ||
        this.detailMsg?.spec.organizationType === '其他组织'
      ) {
        this.formData.businessLicenseCopy =
          this.detailMsg.spec.businessLicenseInfo?.businessLicenseCopy ?? '';
        this.formData.businessLicenseNumber =
          this.detailMsg.spec.businessLicenseInfo?.businessLicenseNumber ?? '';
        this.formData.merchantName =
          this.detailMsg.spec.businessLicenseInfo?.merchantName ?? '';
        this.formData.legalPerson =
          this.detailMsg.spec.businessLicenseInfo?.legalPerson ?? '';
        this.formData.companyAddress =
          this.detailMsg.spec.businessLicenseInfo?.companyAddress ?? '';
        if (
          this.detailMsg.spec.businessLicenseInfo?.businessTime[1] === '长期'
        ) {
          this.formData.businessType = '长期';
          this.formData.businessTimeStart =
            this.detailMsg.spec.businessLicenseInfo?.businessTime[0];
        } else {
          this.formData.businessType = '定期';
          this.formData.businessTimeStart =
            this.detailMsg.spec.businessLicenseInfo?.businessTime[0] ?? null;
          this.formData.businessTimeEnd =
            this.detailMsg.spec.businessLicenseInfo?.businessTime[1] ?? null;
        }
      }
      this.formData.idDocType =
        this.detailMsg?.spec.common?.idInfo?.idDocType ?? '身份证';
      if (this.detailMsg?.spec.common?.idInfo?.idDocType === '身份证') {
        this.formData.idCardCopy =
          this.detailMsg?.spec.common?.idInfo?.idCardCopy ?? '';
        this.formData.idCardNational =
          this.detailMsg?.spec.common?.idInfo?.idCardNational ?? '';
        this.formData.idCardName =
          this.detailMsg?.spec.common?.idInfo?.idCardName ?? '';
        this.formData.idCardNumber =
          this.detailMsg?.spec.common?.idInfo?.idCardNumber ?? '';
        if (this.detailMsg?.spec.common?.idInfo.idCardValidTime[1] === '长期') {
          this.formData.idCardType = '长期';
          this.formData.idCardValidTimeStart =
            this.detailMsg?.spec.common?.idInfo.idCardValidTime[0];
        } else {
          this.formData.idCardType = '定期';
          this.formData.idCardValidTimeStart =
            this.detailMsg?.spec.common?.idInfo.idCardValidTime[0];
          this.formData.idCardValidTimeEnd =
            this.detailMsg?.spec.common?.idInfo.idCardValidTime[1];
        }
      } else if (
        this.detailMsg?.spec.common?.idInfo?.idDocType === '护照' ||
        this.detailMsg?.spec.common?.idInfo?.idDocType ===
          '中国香港居民来往内地通行证' ||
        this.detailMsg?.spec.common?.idInfo?.idDocType ===
          '中国澳门居民来往内地通行证' ||
        this.detailMsg?.spec.common?.idInfo?.idDocType ===
          '中国台湾居民来往大陆通行证'
      ) {
        this.formData.idDocName =
          this.detailMsg?.spec.common?.idInfo?.idDocName ?? '';
        this.formData.idDocNumber =
          this.detailMsg?.spec.common?.idInfo?.idDocNumber ?? '';
        this.formData.idDocCopy =
          this.detailMsg?.spec.common?.idInfo?.idDocCopy ?? '';
        this.formData.docPeriodEnd =
          this.detailMsg?.spec.common?.idInfo?.docPeriodEnd ?? '';
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //切换定期长期
  private changeTimeType(type: string) {
    if (type === 'business') {
      this.formData.businessTimeEnd = null;
      this.formData.businessTimeStart = null;
    } else if (type === 'idCard') {
      this.formData.idCardValidTimeEnd = null;
      this.formData.idCardValidTimeStart = null;
    }
  }
  //提交
  private submitForm(type: string) {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          this.submitFlag = true;
          wechatPayMerchantImports
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s => {
                  if (this.formData.idDocType === '身份证') {
                    s(
                      f => f('spec')('common')('idInfo')('idDocType'),
                      this.formData.idDocType,
                    )(
                      f => f('spec')('common')('idInfo')('idCardCopy'),
                      this.formData.idCardCopy,
                    )(
                      f => f('spec')('common')('idInfo')('idCardNational'),
                      this.formData.idCardNational,
                    )(
                      f => f('spec')('common')('idInfo')('idCardName'),
                      this.formData.idCardName,
                    )(
                      f => f('spec')('common')('idInfo')('idCardNumber'),
                      this.formData.idCardNumber,
                    )(
                      f => f('spec')('common')('idInfo')('idCardValidTime'),
                      this.formData.idCardType === '定期'
                        ? ([
                            this.formData.idCardValidTimeStart,
                            this.formData.idCardValidTimeEnd,
                          ] as [Date, Date | '长期'])
                        : ([this.formData.idCardValidTimeStart, '长期'] as [
                            Date,
                            Date | '长期',
                          ]),
                    );
                  } else {
                    s(
                      f => f('spec')('common')('idInfo')('idDocType'),
                      this.formData.idDocType,
                    )(
                      f => f('spec')('common')('idInfo')('idDocName'),
                      this.formData.idDocName,
                    )(
                      f => f('spec')('common')('idInfo')('idDocNumber'),
                      this.formData.idDocNumber,
                    )(
                      f => f('spec')('common')('idInfo')('idDocCopy'),
                      this.formData.idDocCopy,
                    )(
                      f => f('spec')('common')('idInfo')('docPeriodEnd'),
                      this.formData.docPeriodEnd,
                    );
                  }

                  if (
                    this.organizationType !== '小微商户' &&
                    this.organizationType !== '个人卖家'
                  ) {
                    s(
                      f =>
                        f('spec')('businessLicenseInfo')('businessLicenseCopy'),
                      this.formData.businessLicenseCopy,
                    )(
                      f =>
                        f('spec')('businessLicenseInfo')(
                          'businessLicenseNumber',
                        ),
                      this.formData.businessLicenseNumber,
                    )(
                      f => f('spec')('businessLicenseInfo')('merchantName'),
                      this.formData.merchantName,
                    )(
                      f => f('spec')('businessLicenseInfo')('legalPerson'),
                      this.formData.legalPerson,
                    )(
                      f => f('spec')('businessLicenseInfo')('companyAddress'),
                      this.formData.companyAddress,
                    )(
                      f => f('spec')('businessLicenseInfo')('businessTime'),
                      this.formData.businessType === '定期'
                        ? ([
                            this.formData.businessTimeStart,
                            this.formData.businessTimeEnd,
                          ] as [Date, Date | '长期'])
                        : ([this.formData.businessTimeStart, '长期'] as [
                            Date,
                            Date | '长期',
                          ]),
                    );
                  }
                  return s;
                }),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                if (type) {
                  this.$router.push({
                    query: {
                      menu: this.$route.query.menu,
                      type: type,
                      id: this.$route.query.id,
                    },
                  });
                }
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'information',
        id: this.$route.query.id,
      },
    });
  }
  //证件照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successIdDocCopyUpload(response: any) {
    this.formData.idDocCopy = response.file;
  }
  //身份证国徽面照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successIdCardNationalUpload(response: any) {
    this.formData.idCardNational = response.file;
  }
  //身份证人像面照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successIdCardCopyUpload(response: any) {
    this.formData.idCardCopy = response.file;
  }
  //营业执照照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successBlcUpload(response: any) {
    this.formData.businessLicenseCopy = response.file;
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2; //限制图片大小
    if (!isJPEG) {
      this.$message.error('上传图片只能是 JPG 格式!');
      throw new Error();
    } else if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.spec.channels[0].channelId,
          this.platformConfig.spec.applicationId,
          true,
          [],
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
}
