import { render, staticRenderFns } from "./businessInfor.vue?vue&type=template&id=3db3840b&scoped=true&"
import script from "./businessInfor.vue?vue&type=script&lang=ts&"
export * from "./businessInfor.vue?vue&type=script&lang=ts&"
import style0 from "./businessInfor.vue?vue&type=style&index=0&id=3db3840b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db3840b",
  null
  
)

export default component.exports