
import {Component, Vue} from 'vue-property-decorator';
import merchantManageList from './list.vue';
import merchantDetails from './merchantDetails.vue';
import merchantAccount from './merchantAccount.vue';
@Component({
  name: 'merchantManage',
  components: {merchantManageList, merchantDetails, merchantAccount},
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
