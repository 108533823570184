
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {uploadImg} from '../../../../../api/publicMethod';
import {
  wechatPayMerchantImports,
  platformConfigs,
  downloadRequests,
} from '../../../../../resources';
import {WechatPayMerchantImport} from '@/externals/MaxCI-WechatPayMerchantImport-v1';
import {ObjectId} from 'bson';
import {AxiosError} from 'axios';
import {PlatformConfig} from '@/externals/MaxCI-PlatformConfig-v1';
import {getUserId} from '../../../../../api/publicMethod';

@Component({
  name: 'businessInfor',
})
export default class extends Vue {
  private qualificationsData: Array<{
    uid?: string;
    url?: string;
  }> = [];
  private businessAdditionPicsData: Array<{
    uid?: string;
    url?: string;
  }> = [];
  private formData = {
    qualifications: [] as Array<string>, //特殊资质
    businessAdditionPics: [] as Array<string>, //补充材料
    businessAdditionDesc: '', //补充说明
  }; //提交数据
  private rules = {}; //提交验证
  private submitFlag = false; //提交开关
  private action = '';
  private imgData = {};
  private platformConfig: null | PlatformConfig = null; //平台配置信息
  private detailMsg: null | WechatPayMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private userId = getUserId() as ObjectId;
  private submitShow = false;

  async created() {
    try {
      //查询平台信息
      this.platformConfig =
        (await platformConfigs.find(stage => stage)).find(() => true) ?? null;
      //查询详情
      const wechatPayMerchantImport = (
        await wechatPayMerchantImports.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            ),
          ),
        )
      ).find(() => true);
      this.detailMsg = wechatPayMerchantImport ?? null;
      if (
        this.detailMsg?.spec.common?.idInfo?.idDocType &&
        this.detailMsg?.spec.common?.merchantShortname &&
        this.detailMsg?.spec.common?.contactInfo?.contactName
      ) {
        this.submitShow = true;
      }
      if (
        this.detailMsg?.spec.organizationType === '小微商户' ||
        this.detailMsg?.spec.organizationType === '个人卖家'
      ) {
        this.formData.businessAdditionDesc =
          '该商户已持续从事电子商务经营活动满6个月，且期间经营收入累计超过20万元。';
      } else {
        this.formData.businessAdditionDesc =
          this.detailMsg?.spec.common?.businessAdditionDesc ?? '';
      }
      this.formData.qualifications =
        this.detailMsg?.spec.common?.qualifications ?? [];
      this.formData.businessAdditionPics =
        this.detailMsg?.spec.common?.businessAdditionPics ?? [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  @Watch('formData.qualifications')
  private async changeQualifications() {
    this.changeImg(this.formData.qualifications, 'qualificationsData');
  }
  @Watch('formData.businessAdditionPics')
  private async changeBusinessAdditionPics() {
    this.changeImg(
      this.formData.businessAdditionPics,
      'businessAdditionPicsData',
    );
  }
  private async changeImg(list: Array<string>, type: string) {
    const resources = list.map(v => {
      return {
        spec: {
          uploadId: v,
          userId: getUserId(),
          applicationId: ObjectId.createFromHexString(
            localStorage.getItem('applicationId') ?? '',
          ),
        },
      };
    });
    if (resources.length > 0) {
      const downloadRequest = await downloadRequests.create(resources, {
        watch: {
          filter: filter =>
            filter(
              f => f('operationType'),
              e => e.$eq('update'),
            )(
              f => f('fullDocument')('status')('phase'),
              e => e.$exists(true),
            ),
        },
        fullResource: true,
      });
      if (type === 'qualificationsData') {
        this.qualificationsData = downloadRequest.map(v => {
          return {
            uid: v.spec.uploadId,
            url: v.status?.url,
          };
        });
      } else if (type === 'businessAdditionPicsData') {
        this.businessAdditionPicsData = downloadRequest.map(v => {
          return {
            uid: v.spec.uploadId,
            url: v.status?.url,
          };
        });
      }
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'information',
        id: this.$route.query.id,
      },
    });
  }
  //提交
  private submitForm(type: string) {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          wechatPayMerchantImports
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec')('common')('qualifications'),
                    this.formData.qualifications as [] | [string],
                  )(
                    f => f('spec')('common')('businessAdditionPics'),
                    this.formData.businessAdditionPics as [] | [string],
                  )(
                    f => f('spec')('common')('businessAdditionDesc'),
                    this.formData.businessAdditionDesc,
                  ),
                ),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                if (type) {
                  this.$router.push({
                    query: {
                      menu: this.$route.query.menu,
                      type: type,
                      id: this.$route.query.id,
                    },
                  });
                }
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //补充材料上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async successBusinessAdditionPics(value: {file: string}) {
    this.formData.businessAdditionPics.push(value.file);
  }
  //删除补充材料
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async removeBusinessAdditionPics(value: string, file: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.formData.businessAdditionPics = file.map((v: any) => {
      return v.response.file;
    });
  }
  //特殊资质上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async successQualifications(value: {file: string}) {
    this.formData.qualifications.push(value.file);
  }
  //删除特殊资质
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async removeQualifications(value: string, file: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.formData.qualifications = file.map((v: any) => {
      return v.response.file;
    });
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2; //限制图片大小
    if (!isJPEG) {
      this.$message.error('上传图片只能是 JPG 格式!');
      throw new Error();
    } else if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.spec.channels[0].channelId,
          this.platformConfig.spec.applicationId,
          true,
          [],
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
}
