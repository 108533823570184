
import {Component, Vue} from 'vue-property-decorator';
import {uploadImg} from '../../../../../api/publicMethod';
import {Form} from 'element-ui';
import {wechatPaySpecialMerchantImports} from '../../../../../resources';
import {ObjectId} from 'bson';
import {AxiosError} from 'axios';
import moment from 'moment';
import privateImg from '@/components/privateImg/index.vue';
import {
  WechatPaySpecialMerchantImport,
  SubjectInfo,
} from '@/externals/MaxCI-WechatPaySpecialMerchantImport-v1';

@Component({
  name: 'wechatPaySpecialMerchantSubjectInfor',
  components: {
    privateImg,
  },
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validBusinessNum = (rule: any, value: string, callback: any) => {
    if (!/((^[a-zA-Z0-9]{15}$)|(^[a-zA-Z0-9]{18}$))/.test(value)) {
      callback(new Error('请输入15位或18位的数字/字母'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validbMerchantName = (rule: any, value: string, callback: any) => {
    if (value) {
      if (this.subjectType === '个体户') {
        const businessMerchantName = value.slice(
          value.length - 2,
          value.length,
        );
        if (businessMerchantName === '公司') {
          callback(new Error('商户名称结尾不能是“公司”'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validPeriodEndEnd = (rule: any, value: string, callback: any) => {
    if (value && this.formData.periodBegin) {
      if (moment(this.formData.periodBegin).isBefore(value)) {
        const periodBegin = moment(this.formData.periodBegin);
        const periodEnd = moment(value);
        const now = moment(new Date());
        const diff1 = periodEnd.diff(periodBegin, 'day');
        const diff2 = periodEnd.diff(now, 'day');
        if (diff1 > 60 && diff2 > 60) {
          callback();
        } else {
          callback(
            new Error('有效期必须大于60天，且失效时间距当前时间需要超过60天'),
          );
        }
      } else {
        callback(new Error('失效日期必须大于生效日期'));
      }
    } else {
      callback();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validIdDocNumber = (rule: any, value: string, callback: any) => {
    if (!/(^[a-zA-Z0-9()（）]{6,12}$)/.test(value)) {
      callback(new Error('法人证件号码格式错误，请输入6-12位数字|字母|括号'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validIdCardNumber = (rule: any, value: string, callback: any) => {
    if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
      callback(new Error('身份证格式不正确'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validDocTimeEnd = (rule: any, value: string, callback: any) => {
    if (value && this.formData.docPeriodBegin) {
      if (moment(this.formData.docPeriodBegin).isBefore(value)) {
        const docPeriodBegin = moment(this.formData.docPeriodBegin);
        const docPeriodEnd = moment(value);
        const now = moment(new Date());
        const diff1 = docPeriodEnd.diff(docPeriodBegin, 'day');
        const diff2 = docPeriodEnd.diff(now, 'day');
        if (diff1 > 60 && diff2 > 60) {
          callback();
        } else {
          callback(
            new Error('有效期必须大于60天，且失效时间距当前时间需要超过60天'),
          );
        }
      } else {
        callback(new Error('失效日期必须大于生效日期'));
      }
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validCardValidTimeEnd = (rule: any, value: string, callback: any) => {
    if (value && this.formData.cardPeriodBegin) {
      if (moment(this.formData.cardPeriodBegin).isBefore(value)) {
        const cardPeriodBegin = moment(this.formData.cardPeriodBegin);
        const cardPeriodEnd = moment(value);
        const now = moment(new Date());
        const diff1 = cardPeriodEnd.diff(cardPeriodBegin, 'day');
        const diff2 = cardPeriodEnd.diff(now, 'day');
        if (diff1 > 60 && diff2 > 60) {
          callback();
        } else {
          callback(
            new Error('有效期必须大于60天，且失效时间距当前时间需要超过60天'),
          );
        }
      } else {
        callback(new Error('失效日期必须大于生效日期'));
      }
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validBenefitTimeEnd = (rule: any, value: string, callback: any) => {
    if (value && this.formData.idPeriodBegin) {
      if (moment(this.formData.idPeriodBegin).isBefore(value)) {
        const idPeriodBegin = moment(this.formData.idPeriodBegin);
        const idPeriodEnd = moment(value);
        const now = moment(new Date());
        const diff1 = idPeriodEnd.diff(idPeriodBegin, 'day');
        const diff2 = idPeriodEnd.diff(now, 'day');
        if (diff1 > 60 && diff2 > 60) {
          callback();
        } else {
          callback(
            new Error('有效期必须大于60天，且失效时间距当前时间需要超过60天'),
          );
        }
      } else {
        callback(new Error('失效日期必须大于生效日期'));
      }
    } else {
      callback();
    }
  };
  private formData = {
    //营业执照
    licenseCopy: '', //证件扫描件
    licenseNumber: '', //证件注册号
    businessMerchantName: '', //商户名称
    businessLegalPerson: '', //经营者/法定代表人姓名
    //登记证书
    certCopy: '', //登记证书照片
    certType: '', //登记证书类型
    certNumber: '', //证书号
    certMerchantName: '', //商户名称
    companyAddress: '', //注册地址
    certLegalPerson: '', //法人姓名
    certTimeType: '定期', //证书期限类型
    periodBegin: null as null | Date, //证书期限生效日期
    periodEnd: null as null | Date, //证书期限失效日期
    schoolLicensePic: '', //办学许可证
    //身份验证
    verification: '汇款验证', //验证方式
    certificateLetterCopy: '', //单位证明函照片
    idDocType: '身份证' as
      | '身份证'
      | '护照'
      | '中国香港居民来往内地通行证'
      | '中国澳门居民来往内地通行证'
      | '中国台湾居民来往大陆通行证', //证件类型
    //身份证
    idCardCopy: '', //身份证人像面照片
    idCardNational: '', //身份证国徽面照片
    idCardName: '', //身份证姓名
    idCardNumber: '', //身份证号
    idCardType: '定期', //证件有效期类型
    cardPeriodBegin: null as null | Date, //身份证生效日期
    cardPeriodEnd: null as null | Date, //身份证失效日期
    //其他证件
    idDocName: '', //证件姓名
    idDocNumber: '', //证件号码
    idDocCopy: '', //证件照片
    docType: '定期', //证件有效期类型
    docPeriodBegin: null as null | Date, //证件生效日期
    docPeriodEnd: null as null | Date, //证件失效日期
    owner: true, //是否为受益所有人
    //受益人信息
    idType: '身份证' as
      | '身份证'
      | '护照'
      | '中国香港居民来往内地通行证'
      | '中国澳门居民来往内地通行证'
      | '中国台湾居民来往大陆通行证', //证件类型
    name: '', //受益人姓名
    idNumber: '', //证件号码
    idPeriodType: '定期',
    idPeriodBegin: null as null | Date, //证件生效日期
    idPeriodEnd: null as null | Date, //证件失效日期
    benefiIdCardCopy: '', //身份证人像面照片
    benefitIdCardNational: '', //身份证国徽面照片
    benefitIdDocCopy: '', //证件照片
  }; //提交数据
  private rules = {
    //营业执照
    licenseCopy: [
      {required: true, message: '请上传营业执照照片', trigger: 'blur'},
    ],
    licenseNumber: [
      {required: true, message: '请输入注册号', trigger: 'blur'},
      {validator: this.validBusinessNum, trigger: 'blur'},
    ],
    businessMerchantName: [
      {required: true, message: '请输入商户名称', trigger: 'blur'},
      {validator: this.validbMerchantName, trigger: 'blur'},
    ],
    businessLegalPerson: [
      {required: true, message: '请输入经营者姓名', trigger: 'blur'},
    ],
    //登记证书
    certCopy: [
      {required: true, message: '请上传登记证书照片', trigger: 'blur'},
    ],
    certType: [
      {required: true, message: '请选择登记证书类型', trigger: 'blur'},
    ],
    certNumber: [{required: true, message: '请输入证件号', trigger: 'blur'}],
    certMerchantName: [
      {required: true, message: '请输入商户名称', trigger: 'blur'},
    ],
    companyAddress: [
      {required: true, message: '请输入注册地址', trigger: 'blur'},
    ],
    certLegalPerson: [
      {required: true, message: '请输入法人姓名', trigger: 'blur'},
    ],
    certTimeType: [
      {required: true, message: '请选择证书期限类型', trigger: 'blur'},
    ],
    periodBegin: [{required: true, message: '请选择生效日期', trigger: 'blur'}],
    periodEnd: [
      {required: true, message: '请选择失效日期', trigger: 'blur'},
      {validator: this.validPeriodEndEnd, trigger: 'blur'},
    ],
    certificateLetterCopy: [
      {required: true, message: '请上传', trigger: 'blur'},
    ],
    //身份证件
    idCardCopy: [
      {required: true, message: '请上传身份证人像面照片', trigger: 'blur'},
    ],
    idCardNational: [
      {required: true, message: '请上传身份证国徽面照片', trigger: 'blur'},
    ],
    idCardName: [
      {required: true, message: '请输入身份证姓名', trigger: 'blur'},
    ],
    idCardNumber: [
      {required: true, message: '请输入身份证号', trigger: 'blur'},
      {validator: this.validIdCardNumber, trigger: 'blur'},
    ],
    cardPeriodBegin: [
      {required: true, message: '请选择生效日期', trigger: 'blur'},
    ],
    cardPeriodEnd: [
      {required: true, message: '请选择失效日期', trigger: 'blur'},
      {validator: this.validCardValidTimeEnd, trigger: 'blur'},
    ],
    idDocName: [{required: true, message: '请输入证件姓名', trigger: 'blur'}],
    idDocNumber: [
      {required: true, message: '请输入证件号码', trigger: 'blur'},
      {validator: this.validIdDocNumber, trigger: 'blur'},
    ],
    idDocCopy: [{required: true, message: '请上传证件照片', trigger: 'blur'}],
    docPeriodBegin: [
      {required: true, message: '请选择生效日期', trigger: 'blur'},
    ],
    docPeriodEnd: [
      {required: true, message: '请选择失效日期', trigger: 'blur'},
      {validator: this.validDocTimeEnd, trigger: 'blur'},
    ],
    owner: [
      {required: true, message: '请选择法人是否为受益人', trigger: 'blur'},
    ],
    //受益人信息
    name: [{required: true, message: '请输入证件姓名', trigger: 'blur'}],
    idNumber: [{required: true, message: '请输入证件号码', trigger: 'blur'}],
    idPeriodBegin: [
      {required: true, message: '请选择生效日期', trigger: 'blur'},
    ],
    idPeriodEnd: [
      {required: true, message: '请选择失效日期', trigger: 'blur'},
      {validator: this.validBenefitTimeEnd, trigger: 'blur'},
    ],
    benefiIdCardCopy: [
      {required: true, message: '请上传身份证人像面照片', trigger: 'blur'},
    ],
    benefitIdCardNational: [
      {required: true, message: '请上传身份证国徽面照片', trigger: 'blur'},
    ],
    benefitIdDocCopy: [
      {required: true, message: '请上传证件照片', trigger: 'blur'},
    ],
  }; //提交验证
  private submitFlag = false; //提交开关
  private action = '';
  private imgData = {};

  private detailMsg: null | WechatPaySpecialMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private subjectType = this.$route.query.subjectType as string;

  async created() {
    try {
      //查询详情
      const wechatPaySpecialMerchantImport = (
        await wechatPaySpecialMerchantImports.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      this.detailMsg = wechatPaySpecialMerchantImport ?? null;
      //营业执照
      this.formData.licenseCopy =
        this.detailMsg?.spec.subjectInfo?.businessLicenseInfo?.licenseCopy ??
        '';
      this.formData.licenseNumber =
        this.detailMsg?.spec.subjectInfo?.businessLicenseInfo?.licenseNumber ??
        '';
      this.formData.businessMerchantName =
        this.detailMsg?.spec.subjectInfo?.businessLicenseInfo?.merchantName ??
        '';
      this.formData.businessLegalPerson =
        this.detailMsg?.spec.subjectInfo?.businessLicenseInfo?.legalPerson ??
        '';
      //登记证书
      this.formData.certCopy =
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.certCopy ?? '';
      this.formData.certType =
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.certType ?? '';
      this.formData.certNumber =
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.certNumber ?? '';
      this.formData.certMerchantName =
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.merchantName ?? '';
      this.formData.companyAddress =
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.companyAddress ?? '';
      this.formData.certLegalPerson =
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.legalPerson ?? '';
      this.formData.periodBegin =
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.periodBegin ?? null;
      if (
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.periodEnd === '长期'
      ) {
        this.formData.certTimeType = '长期';
      } else {
        this.formData.certTimeType = '定期';
        this.formData.periodEnd =
          this.detailMsg?.spec.subjectInfo?.certificateInfo?.periodEnd ?? null;
      }
      this.formData.schoolLicensePic =
        this.detailMsg?.spec.subjectInfo?.certificateInfo?.schoolLicensePic ??
        '';
      //单位证明函
      if (this.detailMsg?.spec.subjectInfo?.certificateLetterCopy) {
        this.formData.verification = '单位证明函';
        this.formData.certificateLetterCopy =
          this.detailMsg?.spec.subjectInfo?.certificateLetterCopy ?? '';
      } else {
        this.formData.verification = '汇款验证';
      }
      //经营者法人证件信息
      this.formData.idDocType =
        this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocType ?? '身份证';
      if (
        this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocType === '身份证'
      ) {
        this.formData.idCardCopy =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.idCardCopy ?? '';
        this.formData.idCardNational =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.idCardNational ?? '';
        this.formData.idCardName =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.idCardName ?? '';
        this.formData.idCardNumber =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.idCardNumber ?? '';
        this.formData.cardPeriodBegin =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.cardPeriodBegin ??
          null;
        if (
          this.detailMsg?.spec.subjectInfo?.identityInfo?.cardPeriodEnd ===
          '长期'
        ) {
          this.formData.idCardType = '长期';
        } else {
          this.formData.idCardType = '定期';
          this.formData.cardPeriodEnd =
            this.detailMsg?.spec.subjectInfo?.identityInfo?.cardPeriodEnd ??
            null;
        }
      } else if (
        this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocType === '护照' ||
        this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocType ===
          '中国香港居民来往内地通行证' ||
        this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocType ===
          '中国澳门居民来往内地通行证' ||
        this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocType ===
          '中国台湾居民来往大陆通行证'
      ) {
        this.formData.idDocName =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocName ?? '';
        this.formData.idDocNumber =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocNumber ?? '';
        this.formData.idDocCopy =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.idDocCopy ?? '';
        this.formData.docPeriodBegin =
          this.detailMsg?.spec.subjectInfo?.identityInfo?.docPeriodBegin ??
          null;
        if (
          this.detailMsg?.spec.subjectInfo?.identityInfo?.docPeriodEnd ===
          '长期'
        ) {
          this.formData.docType = '长期';
        } else {
          this.formData.docType = '定期';
          this.formData.docPeriodEnd =
            this.detailMsg?.spec.subjectInfo?.identityInfo?.docPeriodEnd ??
            null;
        }
      }
      //是否为受益人
      this.formData.owner = this.detailMsg?.spec.subjectInfo?.owner ?? true;
      if (!this.formData.owner) {
        //收益人信息
        this.formData.idType =
          this.detailMsg?.spec.subjectInfo?.uboInfo?.idType ?? '身份证';
        this.formData.name =
          this.detailMsg?.spec.subjectInfo?.uboInfo?.name ?? '';
        this.formData.idNumber =
          this.detailMsg?.spec.subjectInfo?.uboInfo?.idNumber ?? '';
        this.formData.idPeriodBegin =
          this.detailMsg?.spec.subjectInfo?.uboInfo?.idPeriodBegin ?? null;
        if (this.detailMsg?.spec.subjectInfo?.uboInfo?.idPeriodEnd === '长期') {
          this.formData.idPeriodType = '长期';
        } else {
          this.formData.idPeriodType = '定期';
          this.formData.idPeriodEnd =
            this.detailMsg?.spec.subjectInfo?.uboInfo?.idPeriodEnd ?? null;
        }
        if (this.detailMsg?.spec.subjectInfo?.uboInfo?.idType === '身份证') {
          this.formData.benefiIdCardCopy =
            this.detailMsg?.spec.subjectInfo?.uboInfo?.idCardCopy ?? '';
          this.formData.benefitIdCardNational =
            this.detailMsg?.spec.subjectInfo?.uboInfo?.idCardNational ?? '';
        } else if (
          this.detailMsg?.spec.subjectInfo?.uboInfo?.idType === '护照' ||
          this.detailMsg?.spec.subjectInfo?.uboInfo?.idType ===
            '中国香港居民来往内地通行证' ||
          this.detailMsg?.spec.subjectInfo?.uboInfo?.idType ===
            '中国澳门居民来往内地通行证' ||
          this.detailMsg?.spec.subjectInfo?.uboInfo?.idType ===
            '中国台湾居民来往大陆通行证'
        ) {
          this.formData.benefitIdDocCopy =
            this.detailMsg?.spec.subjectInfo?.uboInfo?.idDocCopy ?? '';
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //切换定期长期
  private changeTimeType(type: string) {
    if (type === 'cert') {
      this.formData.periodBegin = null;
      this.formData.periodEnd = null;
    } else if (type === 'docCard') {
      this.formData.docPeriodBegin = null;
      this.formData.docPeriodEnd = null;
    } else if (type === 'idCard') {
      this.formData.cardPeriodEnd = null;
      this.formData.cardPeriodBegin = null;
    } else if (type === 'benefit') {
      this.formData.idPeriodBegin = null;
      this.formData.idPeriodEnd = null;
    }
  }
  //提交
  private submitForm(type: string) {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          this.submitFlag = true;
          const data = {
            ...(this.subjectType === '个体户' || this.subjectType === '企业'
              ? {
                  businessLicenseInfo: {
                    licenseCopy: this.formData.licenseCopy,
                    licenseNumber: this.formData.licenseNumber,
                    merchantName: this.formData.businessMerchantName,
                    legalPerson: this.formData.businessLegalPerson,
                  },
                }
              : {}),
            ...(this.subjectType === '事业单位' ||
            this.subjectType === '其他组织'
              ? {
                  certificateInfo: {
                    certCopy: this.formData.certCopy,
                    certType: this.formData.certType,
                    certNumber: this.formData.certNumber,
                    merchantName: this.formData.certMerchantName,
                    companyAddress: this.formData.companyAddress,
                    legalPerson: this.formData.certLegalPerson,
                    periodBegin: this.formData.periodBegin,
                    periodEnd:
                      this.formData.certTimeType === '定期'
                        ? this.formData.periodEnd
                        : '长期',
                    schoolLicensePic: this.formData.schoolLicensePic,
                  },
                }
              : {}),
            ...(this.formData.verification === '汇款验证'
              ? {}
              : {
                  certificateLetterCopy: this.formData.certificateLetterCopy,
                }),
            identityInfo: {
              ...(this.formData.idDocType === '身份证'
                ? {
                    idDocType: this.formData.idDocType,
                    idCardCopy: this.formData.idCardCopy,
                    idCardNational: this.formData.idCardNational,
                    idCardName: this.formData.idCardName,
                    idCardNumber: this.formData.idCardNumber,
                    cardPeriodBegin: this.formData.cardPeriodBegin,
                    cardPeriodEnd:
                      this.formData.idCardType === '定期'
                        ? this.formData.cardPeriodEnd
                        : '长期',
                  }
                : {
                    idDocType: this.formData.idDocType,
                    idDocName: this.formData.idDocName,
                    idDocNumber: this.formData.idDocNumber,
                    idDocCopy: this.formData.idDocCopy,
                    docPeriodBegin: this.formData.docPeriodBegin,
                    docPeriodEnd:
                      this.formData.docType === '定期'
                        ? this.formData.docPeriodEnd
                        : '长期',
                  }),
            },
            owner: this.formData.owner,
            ...(this.formData.owner
              ? {}
              : {
                  uboInfo: {
                    idType: this.formData.idType,
                    ...(this.formData.idType === '身份证'
                      ? {
                          idCardCopy: this.formData.benefiIdCardCopy,
                          idCardNational: this.formData.benefitIdCardNational,
                        }
                      : {
                          idDocCopy: this.formData.benefitIdDocCopy,
                        }),
                    name: this.formData.name,
                    idNumber: this.formData.idNumber,
                    idPeriodBegin: this.formData.idPeriodBegin,
                    idPeriodEnd:
                      this.formData.idPeriodType === '定期'
                        ? this.formData.idPeriodEnd
                        : '长期',
                  },
                }),
          };
          wechatPaySpecialMerchantImports
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(f => f('spec')('subjectInfo'), data as SubjectInfo),
                ),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                if (type) {
                  this.$router.push({
                    query: {
                      menu: this.$route.query.menu,
                      type: type,
                      id: this.$route.query.id,
                    },
                  });
                }
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'information',
        id: this.$route.query.id,
      },
    });
  }
  //受益人身份证国徽面照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successBIdCardNationalUpload(response: any) {
    this.formData.benefitIdCardNational = response.file;
  }
  //受益人身份证人像面照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successBIdCardCopyUpload(response: any) {
    this.formData.benefiIdCardCopy = response.file;
  }
  //受益人证件照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successBenefitIdDocCopyUpload(response: any) {
    this.formData.benefitIdDocCopy = response.file;
  }
  //证件照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successIdDocCopyUpload(response: any) {
    this.formData.idDocCopy = response.file;
  }
  //身份证国徽面照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successIdCardNationalUpload(response: any) {
    this.formData.idCardNational = response.file;
  }
  //身份证人像面照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successIdCardCopyUpload(response: any) {
    this.formData.idCardCopy = response.file;
  }
  //办学许可证上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successSchoolPicUpload(response: any) {
    this.formData.schoolLicensePic = response.file;
  }
  //单位证明函照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successCertificateLetterUpload(response: any) {
    this.formData.certificateLetterCopy = response.file;
  }
  //登记证书照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successCertCopyUpload(response: any) {
    this.formData.certCopy = response.file;
  }
  //营业执照照片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successBlcUpload(response: any) {
    this.formData.licenseCopy = response.file;
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2; //限制图片大小
    if (!isJPEG) {
      this.$message.error('上传图片只能是 JPG 格式!');
      throw new Error();
    } else if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.channels[0].channelId,
          this.platformConfig.applicationId,
          true,
          [],
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
}
