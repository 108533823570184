
import {Component, Vue} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {wechatPayMerchantImports} from '../../../../../resources';
import {WechatPayMerchantImport} from '@/externals/MaxCI-WechatPayMerchantImport-v1';
import {ObjectId} from 'bson';
import {AxiosError} from 'axios';

@Component({
  name: 'businessInfor',
})
export default class extends Vue {
  private formData = {
    merchantShortname: '', //商户简称
    storeName: '', //店铺名称
    miniProgramSubAppid: '', //小程序AppID
  }; //提交数据
  private rules = {
    merchantShortname: [
      {required: true, message: '请输入商户简称', trigger: 'blur'},
    ],
    storeName: [{required: true, message: '请输入店铺名称', trigger: 'blur'}],
  }; //提交验证
  private submitFlag = false; //提交开关
  private detailMsg: null | WechatPayMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );

  async created() {
    try {
      const wechatPayMerchantImport = (
        await wechatPayMerchantImports.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      this.detailMsg = wechatPayMerchantImport ?? null;
      this.formData.merchantShortname =
        this.detailMsg?.spec.common?.merchantShortname ?? '';
      this.formData.storeName =
        this.detailMsg?.spec.common?.salesSceneInfo?.storeName ?? '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'information',
        id: this.$route.query.id,
      },
    });
  }
  //提交
  private submitForm(type: string) {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          wechatPayMerchantImports
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec')('common')('merchantShortname'),
                    this.formData.merchantShortname,
                  )(
                    f => f('spec')('common')('salesSceneInfo')('storeName'),
                    this.formData.storeName,
                  ),
                ),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                if (type) {
                  this.$router.push({
                    query: {
                      type: type,
                      id: this.$route.query.id,
                      menu: this.$route.query.menu,
                    },
                  });
                }
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
}
