
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {payTransactions, payMerchants} from '../../../../../resources';
import {ObjectId} from 'bson';
import {PayMerchant} from '@/externals/MaxCI-PayMerchant-v1';
import moment from 'moment';
import xlsx from 'xlsx';
import {PayTransaction} from '@/externals/MaxCI-PayTransaction-v1';

interface ListPayTransactions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _id: any;
  income: number;
  expenditure: number;
}
interface IdPayMerchant extends PayMerchant {
  id: string;
}
@Component({
  name: 'billingDetailsList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  private payMerchant: Array<IdPayMerchant> = []; //微信商户
  private total = 0;
  private list: Array<PayTransaction> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private time = '-1';
  private searchData = {
    payMerchantId: '',
    payMerchantType: '' as '' | '微信',
    time: [
      moment({hour: 0}).subtract({day: 1}).toDate(),
      moment({hour: 0}).subtract({day: 1}).toDate(),
    ],
    id: '',
    orderId: '',
    orderType: '',
  };
  private summaryData: null | ListPayTransactions = null;
  async created() {
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    if (this.$route.query.time) {
      this.time = '1000';
      this.searchData.time = [
        moment(this.$route.query.time as string).toDate(),
        moment(this.$route.query.time as string).toDate(),
      ];
    }
    if (this.$route.query.payMerchantId) {
      this.searchData.payMerchantId = this.$route.query.payMerchantId as string;
    }
    //查询支付商户
    this.payMerchant = (
      await payMerchants.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        ),
      )
    ).map(v => {
      return {
        ...v,
        id: v._id.toHexString(),
      };
    });
    this.updateList();
  }
  //查看详情
  private to_detail(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkList();
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.listParams.page = 1;
    this.updateList();
  }
  private removeReduce(num: number) {
    return Number(num.toString().replace('-', ''));
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //获取商户名称
  private getPayMerchantName(id: ObjectId) {
    let name = '';
    this.payMerchant.forEach(item => {
      if (item._id.equals(id)) {
        name = item?.spec.name;
      }
    });
    return name;
  }
  //查询列表
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await payTransactions.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                );
                if (this.searchData.payMerchantId) {
                  query(
                    f => f('spec')('payMerchantId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.searchData.payMerchantId,
                        ),
                      ),
                  );
                }
                if (this.searchData.payMerchantType) {
                  query(
                    f => f('spec')('payMerchantType'),
                    e => e.$eq(this.searchData.payMerchantType as '微信'),
                  );
                }
                if (this.searchData.id) {
                  query(
                    f => f('_id'),
                    e =>
                      e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                  );
                }
                if (this.searchData.orderId) {
                  query(
                    f => f('spec')('orderId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.orderId),
                      ),
                  );
                }
                if (this.searchData.orderType) {
                  query(
                    f => f('spec')('orderType'),
                    e => e.$eq(this.searchData.orderType as '商城'),
                  );
                }
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('updationTimestamp'),
                    e => e.$gte(moment(this.searchData.time[0]).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('updationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1])
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('updationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count'))(
              'summary',
              summaryStage =>
                summaryStage.$group(
                  e => e.$literal(null),
                  group =>
                    group('income', e =>
                      e.$sum(sum =>
                        sum.$cond(
                          _if =>
                            _if.$eq(
                              e => e.$fieldPath(f => f('spec')('direction')),
                              e => e.$literal('收入'),
                            ),
                          _then =>
                            _then.$fieldPath(f => f('spec')('amount')('user')),
                          _else => _else.$literal(0),
                        ),
                      ),
                    )('expenditure', e =>
                      e.$sum(sum =>
                        sum.$cond(
                          _if =>
                            _if.$eq(
                              e => e.$fieldPath(f => f('spec')('direction')),
                              e => e.$literal('支出'),
                            ),
                          _then =>
                            _then.$fieldPath(f => f('spec')('amount')('user')),
                          _else => _else.$literal(0),
                        ),
                      ),
                    ),
                ),
            ),
          ),
      );
      this.summaryData = list[0].summary[0];
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  // 导出报表
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = (
        await payTransactions.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  query(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  );
                  if (this.searchData.payMerchantId) {
                    query(
                      f => f('spec')('payMerchantId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(
                            this.searchData.payMerchantId,
                          ),
                        ),
                    );
                  }
                  if (this.searchData.payMerchantType) {
                    query(
                      f => f('spec')('payMerchantType'),
                      e => e.$eq(this.searchData.payMerchantType as '微信'),
                    );
                  }
                  if (this.searchData.id) {
                    query(
                      f => f('_id'),
                      e =>
                        e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                    );
                  }
                  if (this.searchData.orderId) {
                    query(
                      f => f('spec')('orderId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.orderId),
                        ),
                    );
                  }
                  if (this.searchData.orderType) {
                    query(
                      f => f('spec')('orderType'),
                      e => e.$eq(this.searchData.orderType as '商城'),
                    );
                  }
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('count', countStage => countStage.$count('count')),
            ),
        )
      )[0].count[0].count.valueOf();
      reportList.push([
        '记账时间',
        '流水单号',
        '关联订单号',
        '支付商户',
        '支付方式',
        '类型',
        '收支金额(￥)',
      ]);
      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await payTransactions.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  query(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  );
                  if (this.searchData.payMerchantId) {
                    query(
                      f => f('spec')('payMerchantId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(
                            this.searchData.payMerchantId,
                          ),
                        ),
                    );
                  }
                  if (this.searchData.payMerchantType) {
                    query(
                      f => f('spec')('payMerchantType'),
                      e => e.$eq(this.searchData.payMerchantType as '微信'),
                    );
                  }
                  if (this.searchData.id) {
                    query(
                      f => f('_id'),
                      e =>
                        e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                    );
                  }
                  if (this.searchData.orderId) {
                    query(
                      f => f('spec')('orderId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.orderId),
                        ),
                    );
                  }
                  if (this.searchData.orderType) {
                    query(
                      f => f('spec')('orderType'),
                      e => e.$eq(this.searchData.orderType as '商城'),
                    );
                  }
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$sort(sort =>
                    sort(f => f('metadata')('updationTimestamp'), '降序'),
                  )
                  .$skip((i - 1) * 100)
                  .$limit(100),
              ),
            ),
        );
        list[0].table.forEach(item => {
          reportList.push([
            this.getTime(item.metadata.updationTimestamp ?? ''),
            item._id.toHexString(),
            item.spec.orderId.toHexString(),
            this.getPayMerchantName(item.spec.payMerchantId),
            item.spec.payMerchantType,
            item.spec.orderType,
            Number((item.spec.amount.user / 100).toFixed(2)),
          ]);
        });
      }
      const filename = moment().format('YYYY-MM-DD') + '应用账单明细.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
}
