
import {Component, Vue} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {wechatPaySpecialMerchantImports} from '../../../../../resources';
import {WechatPaySpecialMerchantImport} from '@/externals/MaxCI-WechatPaySpecialMerchantImport-v1';
import {ObjectId} from 'bson';
import {AxiosError} from 'axios';

@Component({
  name: 'wechatPaySpecialMerchantBusinessInfor',
})
export default class extends Vue {
  private validPhone = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^1[23456789]\d{9}$/.test(value)) {
      callback(new Error('请输入正确的手机号'));
    } else {
      callback();
    }
  };
  private formData = {
    name: '', //商户名称
    merchantShortname: '', //商户简称
    servicePhone: '', //客服电话
  }; //提交数据
  private rules = {
    name: [{required: true, message: '请输入商户名称', trigger: 'blur'}],
    merchantShortname: [
      {required: true, message: '请输入商户简称', trigger: 'blur'},
    ],
    servicePhone: [
      {required: true, message: '请输入客服电话', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
  }; //提交验证
  private submitFlag = false; //提交开关
  private detailMsg: null | WechatPaySpecialMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );

  async created() {
    try {
      const wechatPaySpecialMerchantImport = (
        await wechatPaySpecialMerchantImports.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      this.detailMsg = wechatPaySpecialMerchantImport ?? null;
      this.formData.name = this.detailMsg?.spec.name ?? '';
      this.formData.merchantShortname =
        this.detailMsg?.spec.businessInfo?.merchantShortname ?? '';
      this.formData.servicePhone =
        this.detailMsg?.spec.businessInfo?.servicePhone ?? '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'information',
        id: this.$route.query.id,
      },
    });
  }
  //提交
  private submitForm(type: string) {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          wechatPaySpecialMerchantImports
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(f => f('spec')('name'), this.formData.name)(
                    f => f('spec')('businessInfo')('merchantShortname'),
                    this.formData.merchantShortname,
                  )(
                    f => f('spec')('businessInfo')('servicePhone'),
                    this.formData.servicePhone,
                  ),
                ),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                if (type) {
                  this.$router.push({
                    query: {
                      type: type,
                      id: this.$route.query.id,
                      menu: this.$route.query.menu,
                    },
                  });
                }
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
}
