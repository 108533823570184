
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import vueQr from 'vue-qr';
import {
  payMerchants,
  channelRequests,
  channels,
} from '../../../../../resources';
import {ObjectId} from 'bson';
import {
  PayMerchant,
  AlipayMerchantSpec,
  WechatPaySpecialMerchantSpec,
} from '@/externals/MaxCI-PayMerchant-v1';
import {Form} from 'element-ui';
@Component({
  name: 'merchantIncomingList',
  components: {
    multiSelect,
    Pagination,
    vueQr,
  },
})
export default class extends Vue {
  private authorizeAlipay = '';
  private dialogAuthorize = false;
  private dialogDel = false;
  private formRules = {
    type: [{required: true, message: '请选择', trigger: 'blur'}],
    name: [{required: true, message: '请输入商户名称', trigger: 'blur'}],
    subMchid: [{required: true, message: '请输入商户号', trigger: 'blur'}],
  };
  private formData = {
    type: '支付宝' as '支付宝' | '微信',
    name: '',
    subMchid: '',
    appAuthToken: '',
  };
  private saveLoading = false;
  private dialogAlipay = false;
  private total = 0;
  private list: Array<PayMerchant> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {name: ''};
  private operateTitle = '';
  private operateId: ObjectId | null = null;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    this.updateList();
  }
  //授权弹窗
  private async authorizePopUps(id: ObjectId) {
    const channel = (
      await channels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('spec')('type'),
            e => e.$eq('支付宝小程序'),
          )(
            f => f('status')('phase'),
            e => e.$ne('删除'),
          ),
        ),
      )
    ).find(() => true);
    if (!channel) {
      this.$message.error('暂无支付宝渠道，请先添加');
      return;
    }
    this.operateId = id;
    this.dialogAuthorize = true;
    this.authorizeAlipay = '';
    const redirect_uri = encodeURI(
      new URL('/#/authorize', window.location.origin).href,
    );
    console.log('redirect_uri', redirect_uri);
    const channelRequest = await channelRequests.create(
      [
        {
          spec: {
            type: '更新授权',
            applicationId: this.applicationId,
            channelId: channel._id,
          },
        },
      ],
      {
        watch: {
          filter: filter =>
            filter(
              f => f('operationType'),
              e => e.$eq('update'),
            )(
              f => f('fullDocument')('status')('phase'),
              e => e.$exists(true),
            ),
        },
        fullResource: true,
      },
    );
    if (channelRequest[0].status?.phase === '成功') {
      const link = new URL(
        'https://openauth.alipay.com/oauth2/appToAppBatchAuth.htm',
      );
      link.searchParams.set('app_id', channelRequest[0].status?.appId ?? '');
      link.searchParams.set('application_type', 'WEBAPP');
      link.searchParams.set('redirect_uri', redirect_uri);
      link.searchParams.set('state', `${id.toHexString()}P`);
      console.log(link.href);
      this.authorizeAlipay = link.href;
    } else {
      this.$message.error(
        channelRequest[0].status?.conditions[0].message ?? '',
      );
    }
  }
  //删除提示
  private delPopUps(row: PayMerchant) {
    this.operateId = row._id;
    this.dialogDel = true;
  }
  //删除
  private async delSumbit() {
    try {
      this.saveLoading = true;
      const payMerchant = await payMerchants.delete(filter =>
        filter(
          f => f('_id'),
          e => e.$eq(this.operateId as ObjectId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      );
      if (payMerchant) {
        this.dialogDel = false;
        this.operateId = null;
        this.$message.success('已删除');
        this.updateList();
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.saveLoading = false;
    }
  }
  //更新支付宝小程序弹窗
  private addPopUps(item?: PayMerchant) {
    this.operateId = item?._id ?? null;
    const info = item?.spec as
      | AlipayMerchantSpec
      | WechatPaySpecialMerchantSpec;
    this.operateTitle = item ? '编辑' : '新增';
    if (this.operateTitle === '新增') {
      this.formData = {
        type: '支付宝',
        name: '',
        subMchid: '',
        appAuthToken: '',
      };
    } else {
      this.formData = {
        type: info?.type ?? '',
        name: info?.name ?? '',
        subMchid: info.type === '微信' ? info.subMchid : '',
        appAuthToken: info.type === '支付宝' ? info.appAuthToken ?? '' : '',
      };
    }

    this.dialogAlipay = true;
  }
  //更新支付宝小程序
  private async alipaySumbit() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          this.saveLoading = true;
          let spec = null as
            | AlipayMerchantSpec
            | WechatPaySpecialMerchantSpec
            | null;
          if (this.formData.type === '微信') {
            spec = {
              type: '微信',
              name: this.formData.name,
              applicationId: this.applicationId,
              subMchid: this.formData.subMchid,
              subMchType: '特约商户',
              data: {},
            };
          } else {
            spec = {
              type: '支付宝',
              name: this.formData.name,
              applicationId: this.applicationId,
              ...(this.formData.appAuthToken
                ? {appAuthToken: this.formData.appAuthToken}
                : {}),
            };
          }
          if (this.operateTitle === '新增') {
            const payMerchant = await payMerchants.create([
              {
                spec: spec,
              },
            ]);
            if (payMerchant.length > 0) {
              this.$message.success('新增成功');
              this.dialogAlipay = false;
              this.updateList();
            } else {
              this.$message.error('新增失败');
            }
          } else {
            const payMerchant = await payMerchants.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.operateId as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec'),
                    spec as AlipayMerchantSpec | WechatPaySpecialMerchantSpec,
                  ),
                ),
            );
            if (payMerchant.length > 0) {
              this.$message.success('修改成功');
              this.dialogAlipay = false;
              this.updateList();
            } else {
              this.$message.error('修改失败');
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error(e);
        } finally {
          this.saveLoading = false;
        }
      } else {
        return false;
      }
    });
  }
  //查看详情
  private toDetail(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'merchantDetails',
        id: id.toHexString(),
      },
    });
  }
  //结算账户
  private changeAccount(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'merchantAccount',
        id: id.toHexString(),
      },
    });
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await payMerchants.find(stage =>
        stage
          .$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
