
import {Component, Vue} from 'vue-property-decorator';
import {payMerchants, wechatPayMerchatRequests} from '../../../../../resources';
import {AccountInfo} from '@/externals/MaxCI-PayMerchant-v1';
import {ObjectId} from 'bson';
import privateImg from '@/components/privateImg/index.vue';
import {Form} from 'element-ui';
import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const cn = require('china-region');

@Component({
  name: 'applicationDetails',
  components: {
    privateImg,
  },
})
export default class extends Vue {
  private bankData = [
    {
      value: '63',
      parentbankno: '102',
      quickunionno: '102100099996',
      bankname: '工商银行',
    },
    {
      value: '67',
      parentbankno: '301',
      quickunionno: '301290000007',
      bankname: '交通银行',
    },
    {
      value: '73',
      parentbankno: '308',
      quickunionno: '308584000013',
      bankname: '招商银行',
    },
    {
      value: '72',
      parentbankno: '305',
      quickunionno: '305100000013',
      bankname: '民生银行',
    },
    {
      value: '4',
      parentbankno: '302',
      quickunionno: '302100011000',
      bankname: '中信银行',
    },
    {
      value: '5',
      parentbankno: '309',
      quickunionno: '309391000011',
      bankname: '兴业银行',
    },
    {
      value: '70',
      parentbankno: '303',
      quickunionno: '303100000006',
      bankname: '光大银行',
    },
    {
      value: '71',
      parentbankno: '306',
      quickunionno: '306581000003',
      bankname: '广发银行',
    },
    {
      value: '74',
      parentbankno: '307',
      quickunionno: '307584007998',
      bankname: '平安银行',
    },
    {
      value: '68',
      parentbankno: '304',
      quickunionno: '304100040000',
      bankname: '华夏银行',
    },
    {
      value: '62',
      parentbankno: '103',
      quickunionno: '103100000026',
      bankname: '农业银行',
    },
    {
      value: '60',
      parentbankno: '105',
      quickunionno: '105100000017',
      bankname: '建设银行',
    },
    {
      value: '8',
      parentbankno: '403',
      quickunionno: '403100000004',
      bankname: '邮政储蓄银行',
    },
    {
      value: '61',
      parentbankno: '104',
      quickunionno: '104100000004',
      bankname: '中国银行',
    },
  ]; //银行列表
  private bankNameData = []; //银行全称列表
  private provinceData = [
    {name: '北京市', value: '1', code: '110'},
    {name: '天津市', value: '2', code: '120'},
    {name: '河北省', value: '3', code: '130'},
    {name: '山西省', value: '4', code: '140'},
    {name: '内蒙古自治区', value: '5', code: '150'},
    {name: '辽宁省', value: '6', code: '210'},
    {name: '吉林省', value: '7', code: '220'},
    {name: '黑龙江省', value: '8', code: '230'},
    {name: '上海市', value: '9', code: '310'},
    {name: '江苏省', value: '10', code: '320'},
    {name: '浙江省', value: '11', code: '330'},
    {name: '安徽省', value: '12', code: '340'},
    {name: '福建省', value: '13', code: '350'},
    {name: '江西省', value: '14', code: '360'},
    {name: '山东省', value: '15', code: '370'},
    {name: '河南省', value: '16', code: '410'},
    {name: '湖北省', value: '17', code: '420'},
    {name: '湖南省', value: '18', code: '430'},
    {name: '广东省', value: '19', code: '440'},
    {name: '广西壮族自治区', value: '20', code: '450'},
    {name: '海南省', value: '21', code: '460'},
    {name: '重庆市', value: '22', code: '500'},
    {name: '四川省', value: '23', code: '510'},
    {name: '贵州省', value: '24', code: '520'},
    {name: '云南省', value: '25', code: '530'},
    {name: '西藏自治区', value: '26', code: '540'},
    {name: '陕西省', value: '27', code: '610'},
    {name: '甘肃省', value: '28', code: '620'},
    {name: '青海省', value: '29', code: '630'},
    {name: '宁夏回族自治区', value: '30', code: '640'},
    {name: '新疆维吾尔自治区', value: '31', code: '650'},
    {name: '台湾省', value: '32', code: '710'},
    {name: '香港特别行政区', value: '33', code: '810'},
    {name: '澳门特别行政区', value: '34', code: '820'},
  ]; //省列表
  private cityData = []; //市列表
  private cityCode: Array<{name: string; code: string}> = []; //城市code列表
  private submitFlag = false; //提交开关
  private detailMsg: null | AccountInfo = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private formData = {
    accountType: '', //账户类型
    accountBank: '', //开户银行
    province: '', //省
    city: '', //市
    accountNumber: '', //银行帐号
    bankName: '', //开户银行全称
    bankAddressCode: '', //开户银行省市编码
  };
  private rules = {
    accountType: [{required: true, message: '请选择账户类型', trigger: 'blur'}],
    accountBank: [{required: true, message: '请输入开户银行', trigger: 'blur'}],
    city: [{required: true, message: '请选择市', trigger: 'blur'}],
    bankName: [{required: true, message: '请选择银行全称', trigger: 'blur'}],
    accountNumber: [
      {required: true, message: '请输入银行帐号', trigger: 'blur'},
    ],
  };
  private organizationType = ''; //主体类型
  async created() {
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded';
    axios.defaults.transformRequest = [
      function (data) {
        let ret = '';
        for (let it in data) {
          ret +=
            encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
        }
        return ret;
      },
    ];
    try {
      const payMerchant = (
        await payMerchants.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      if (payMerchant?.spec.type === '微信') {
        this.organizationType =
          (payMerchant?.spec.data.organizationType as string) ?? '';
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //选择省市
  private changeCity() {
    this.checkBankName();
    if (this.cityCode.length === 0) {
      this.formData.bankAddressCode = cn.getCodeByProvinceName(
        this.formData.city,
      );
    } else {
      this.cityCode.forEach(item => {
        if (item.name === this.formData.city.split(',')[0]) {
          this.formData.bankAddressCode = item.code;
          return;
        }
      });
    }
  }
  //选择省
  private changeProvince() {
    const code = cn.getCodeByProvinceName(this.formData.province.split(',')[0]);
    this.cityCode = cn.getPrefectures(code);
    axios({
      method: 'post',
      url: 'https://saobei.lcsw.cn/lcsw/provincecitystd/getcity',
      data: {
        provinceid: Number(this.formData.province.split(',')[1]),
      },
    }).then(res => {
      if (res.status === 200) {
        this.cityData = res.data;
      }
    });
  }
  //选择银行
  private changeBank() {
    this.checkBankName();
  }
  //查询银行全称
  private checkBankName() {
    if (this.formData.accountBank && this.formData.city) {
      axios({
        method: 'post',
        url: 'https://saobei.lcsw.cn/lcsw/bank/getbank',
        data: {
          bankparentid: this.formData.accountBank.split(',')[1],
          cityid: this.formData.city.split(',')[1],
        },
      }).then(res => {
        if (res.status === 200) {
          this.bankNameData = res.data;
        }
      });
    }
  }
  //提交
  private submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          wechatPayMerchatRequests
            .create(
              [
                {
                  spec: {
                    merchantId: this.detailId,
                    applicationId: ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                    accountType: this.formData.accountType as
                      | '对公银行账户'
                      | '经营者个人银行卡',
                    accountBank: this.formData.accountBank.split(',')[0],
                    bankAddressCode: this.formData.bankAddressCode,
                    accountNumber: this.formData.accountNumber,
                    bankName: this.formData.bankName.split(',')[0],
                    bankBranchId: this.formData.bankName.split(',')[1],
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常,请稍后再试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //选择省省市区
  private handleChange(value: Array<string>) {
    console.log(value);
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
