
import {Component, Vue} from 'vue-property-decorator';
import {wechatPayMerchantImports} from '../../../../../resources';
import {
  WechatPayMerchantImport,
  WechatPayMerchantImportStatus,
} from '@/externals/MaxCI-WechatPayMerchantImport-v1';
import {ObjectId} from 'bson';
import moment from 'moment';
import vueQr from 'vue-qr';
@Component({
  name: 'applicationProgress',
  components: {
    vueQr,
  },
})
export default class extends Vue {
  private statusData: Array<{
    name: string;
    text?: string;
    status: string;
    img?: string;
  }> = [];
  private detailMsg: null | WechatPayMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private status = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private reasonData = [] as any;
  private applymentId = 0;
  async created() {
    try {
      const wechatPayMerchantImport = (
        await wechatPayMerchantImports.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true);
      if (wechatPayMerchantImport) {
        this.applymentId = wechatPayMerchantImport.status?.applymentId ?? 0;
        this.reasonData = wechatPayMerchantImport.status?.auditDetail;
        this.status = this.getStatus(wechatPayMerchantImport.status);
        const time = wechatPayMerchantImport.status?.conditions
          ? wechatPayMerchantImport.status?.conditions[0]?.creationTimestamp
          : '';

        if (this.status === '已提交') {
          this.statusData = [
            {
              name: '提交申请',
              text: this.getTime(time),
              status: '1',
            },
            {
              name: '平台审核',
              status: '0',
            },
            {
              name: '账户验证',
              status: '0',
            },
            {
              name: '签署协议',
              status: '0',
            },
          ];
        } else if (this.status === '审核中') {
          this.statusData = [
            {
              name: '提交申请',
              text: this.getTime(time),
              status: '2',
            },
            {
              name: '平台审核',
              status: '1',
              text: '平台审核中',
            },
            {
              name: '账户验证',
              status: '0',
            },
            {
              name: '签署协议',
              status: '0',
            },
          ];
        } else if (this.status === '待账户验证') {
          this.statusData = [
            {
              name: '提交申请',
              text: this.getTime(time),
              status: '2',
            },
            {
              name: '平台审核',
              status: '2',
              text: '审核完成',
            },
            {
              name: '账户验证',
              text:
                '请指引申请单超级管理员' +
                wechatPayMerchantImport.spec.common?.contactInfo?.contactName +
                ',使用微信扫以下二维码(30天内有效,过期后讲生成新的二维码),完成账户验证及签约！',
              status: '1',
              img: wechatPayMerchantImport.status?.legalValidationUrl,
            },
            {
              name: '签署协议',
              status: '0',
            },
          ];
        } else if (this.status === '待签约') {
          this.statusData = [
            {
              name: '提交申请',
              text: this.getTime(time),
              status: '2',
            },
            {
              name: '平台审核',
              status: '2',
              text: '审核完成',
            },
            {
              name: '账户验证',
              text: '账户验证完成',
              status: '2',
            },
            {
              name: '签署协议',
              text:
                '请指引申请单超级管理员' +
                wechatPayMerchantImport.spec.common?.contactInfo?.contactName +
                ',使用微信扫以下二维码(30天内有效,过期后讲生成新的二维码),完成签约！',
              status: '1',
              img: wechatPayMerchantImport.status?.signUrl,
            },
          ];
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //查看资料
  private checkInfo() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'applicationDetails',
        id: this.$route.query.id,
      },
    });
  }
  //修改
  private backEdit() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'information',
        id: this.$route.query.id,
      },
    });
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //获取状态
  private getStatus(status: WechatPayMerchantImportStatus | undefined) {
    let statusText = '';
    if (status) {
      statusText = status.phase ?? '';
    } else {
      statusText = '待提交';
    }
    return statusText;
  }
  private backBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
