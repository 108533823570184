
import {Component, Vue} from 'vue-property-decorator';
import {wechatPayMerchantImports} from '../../../../../resources';
import {ObjectId} from 'bson';
import {WechatPayMerchantImport} from '@/externals/MaxCI-WechatPayMerchantImport-v1';
import moment from 'moment';
@Component({
  name: 'information',
})
export default class extends Vue {
  private dialogType = false; //修改主体类型弹窗
  private infoData = [
    {
      name: '主体信息',
      text: '请填写商家的营业执照/登记证书、经营者/法人的证件等信息',
      status: '',
      type: 'subjectInfor',
    },
    {
      name: '经营信息',
      text: '请填写商家的经营业务信息、售卖商品/提供服务场景信息',
      status: '',
      type: 'businessInfor',
    },
    {
      name: '超级管理员',
      text: '请填写商家的超级管理员信息， 超级管理员需在开户后进行签约，并接收日常重要管理信息和进行资金操作，请确定其为商户法定代表人或负责人',
      status: '',
      type: 'superAdmin',
    },
  ];
  private detailMsg: null | WechatPayMerchantImport = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private organizationType = ''; //主体类型
  private creationTime = ''; //创建时间
  async created() {
    const wechatPayMerchantImport = (
      await wechatPayMerchantImports.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$eq(this.detailId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        ),
      )
    ).find(() => true);
    this.detailMsg = wechatPayMerchantImport ?? null;
    if (this.detailMsg?.spec.common?.idInfo?.idDocType) {
      if (this.detailMsg?.spec.common?.merchantShortname) {
        if (this.detailMsg?.spec.common?.contactInfo?.contactName) {
          this.infoData[0].status = '2';
          this.infoData[1].status = '2';
          this.infoData[2].status = '2';
        } else {
          this.infoData[0].status = '2';
          this.infoData[1].status = '2';
          this.infoData[2].status = '1';
        }
      } else {
        this.infoData[0].status = '2';
        this.infoData[1].status = '1';
        this.infoData[2].status = '0';
      }
    } else {
      this.infoData[0].status = '1';
      this.infoData[1].status = '0';
      this.infoData[2].status = '0';
    }
  }
  //修改主体信息
  private typeSumbit() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'chooseSubject',
        id: this.$route.query.id,
      },
    });
  }
  //填写信息
  private writeInfo(type: string, status: string) {
    if (status === '1' || status === '2') {
      this.$router.push({
        query: {
          menu: this.$route.query.menu,
          type: type,
          id: this.$route.query.id,
          organizationType: this.detailMsg?.spec.organizationType,
        },
      });
    }
  }
  //提交
  private submitForm() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'applicationDetails',
        id: this.$route.query.id,
      },
    });
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  private backBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
