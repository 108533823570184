
import {Component, Vue} from 'vue-property-decorator';
import {wechatPaySpecialMerchantImports} from '../../../../../resources';
import {ObjectId} from 'bson';
@Component({
  name: 'wechatPaySpecialMerchantSubject',
})
export default class extends Vue {
  private mainData: Array<{name: string; text: string}> = [
    {
      name: '个体户',
      text: '营业执照上的主体类型一般为个体户、个体工商户、个体经营',
    },
    {
      name: '企业',
      text: '营业执照上的主体类型一般为有限公司、有限责任公司',
    },
    {
      name: '事业单位',
      text: '包括国内各级、各类政府机构、事业单位等。如：公安、党团、司法、交通、旅游、工商税务、市政、医疗、教育、学校等机构',
    },
    {
      name: '其他组织',
      text: '不属于企业、政府/事业单位的组织机构，如社会团体、民办非企业、基金会。要求机构已办理组织机构代码证',
    },
  ];
  private detailId: null | ObjectId = null;
  async created() {
    if (this.$route.query.id) {
      this.detailId = ObjectId.createFromHexString(
        this.$route.query.id as string,
      );
    }
  }
  private change(name: '个体户' | '企业' | '事业单位' | '其他组织') {
    if (this.detailId) {
      wechatPaySpecialMerchantImports
        .update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.detailId as ObjectId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          update =>
            update.$set(s =>
              s(f => f('spec'), {
                subjectType: name,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
              }),
            ),
        )
        .then(res => {
          if (res.length > 0) {
            this.$router.push({
              query: {
                menu: this.$route.query.menu,
                type: 'information',
                id: res[0]._id.toHexString(),
              },
            });
          }
        });
    } else {
      wechatPaySpecialMerchantImports
        .create([
          {
            spec: {
              subjectType: name,
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
            },
          },
        ])
        .then(res => {
          if (res.length > 0) {
            this.$router.push({
              query: {
                menu: this.$route.query.menu,
                type: 'information',
                id: res[0]._id.toHexString(),
              },
            });
          }
        });
    }
  }
  private backBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
